<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">

      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="code"
        :columns="columns"
        :data-source="list"
        :pagination="false" >

        <span slot="code" slot-scope="text, record">
           <dict-tag :options="dict.type['extra_sms_channel']" :value="record.code"/>
        </span>
        <span slot="status" slot-scope="text, record">
           <dict-tag :options="dict.type['sys_normal_disable']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          <span>{{ parseTime(record.createTime, '{y}-{m}-{d}') }}</span>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record, undefined)" v-hasPermi="['extra:channel:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"/>
           <a @click="handleShowAccount" v-hasPermi="['extra:channel:query']">
            <a-icon type="diff" />  签名与模板
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParams.pageNum"
        :total="total"
        :page-size="queryParams.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <a-drawer :visible.sync="open" width="700px" @close="cancel">
      <a-divider orientation="left">
        <b>{{ title }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="短信签名" prop="signature">
          <a-input v-model="form.signature" placeholder="请输入短信签名" />
        </a-form-model-item>
        <a-form-model-item label="启用狀態">
          <a-radio-group v-model="form.status" button-style="solid">
            <a-radio-button v-for="(d, index) in dict.type.sys_normal_disable" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="備註" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入備註" />
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">确 定</a-button>
          <a-button @click="cancel">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>
  </page-header-wrapper>
</template>
<style scoped  lang="less">
.app-container-card{
  margin-top: 10em;
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    text-align: center;
  }
  .content{
    font-size: 12px;
    text-align: center;
  }
}
</style>

<script>
import { listChannel, getChannel, delChannel, addChannel, updateChannel } from "@/api/sms/channel";
import { crateAccount,getSmsSetMeal} from "@/api/sms/zrwinfo";
import { tableMixin } from '@/store/table-mixin'
export default {
  name: "Channel",
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'extra_sms_channel'],
  data() {
    return {
      labelCol: {span: 5},
      wrapperCol: {span: 18},
      zrid:"",
      accountOpen:false,
      accountTitle:"",
      accountForm:{},
      accountRules: {
        nickname: [
          { required: true, message: "联系人不能为空", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "手機號不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ]
      },
      showCard: true,
      showList: false,
      noZrAccount: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 短信渠道（阿里，腾讯，钉钉等）表格数据
      list: [],
      // 弹出层標題
      title: "",
      // 是否显示弹出层
      open: false,
      // 查詢参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        signature: null,
        code: null,
        status: null,
        apiKey: null,
        apiSecret: null,
        callbackUrl: null,
        isDeleted: null
      },
      // 表单参数
      form: {
        status:1
      },
      // 表单校验
      rules: {
        signature: [
          { required: true, message: "短信签名不能为空", trigger: "blur" }
        ],
        code: [
          { required: true, message: "渠道编码不能为空", trigger: "blur" }
        ],
        status: [
          { required: true, message: "开启狀態不能为空", trigger: "blur" }
        ],
        apiKey: [
          { required: true, message: "短信API的账号不能为空", trigger: "blur" }
        ]
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '短信签名',
          dataIndex: 'signature',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '渠道编码',
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '启用狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '備註',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    };
  },
  created() {
    this.getList(true);
  },
  methods: {
    handleAddAccount(){
      this.accountReset();
      this.accountOpen = true
    },
    submitAccountForm(){
      this.$refs["accountForm"].validate(valid => {
        if (valid) {
          crateAccount(this.accountForm).then(response => {
            if(response.code ===1){
              this.accountOpen = false;
              this.$message.success("申请成功");
            }else{
              this.$message.error(response.msg);
            }
            this.getList(true);
          });
        }
      });
    },
    cancelAccount(){
      this.accountOpen = false;
      this.accountReset();
    },
    accountReset() {
      this.accountForm = {
        nickname: null,
        mobile: null,
        password: null,
        remark: null
      };
      this.resetForm("accountForm");
    },
    handleShowAccount(){
      this.$router.push({
        path:"/sms/zrw-info/index/"+this.zrid
      })
    },
    handleShowList(){
      this.showList = true;
      this.showCard = false;
      this.getList(false);
    },
    handleShowCard(){
      this.showList = false;
      this.showCard = true;
    },
    /** 查詢短信渠道（阿里，腾讯，钉钉等）列表 */
    getList(createFlag) {
      this.loading = true;
      listChannel(this.queryParams).then(response => {
        this.list =  response.data.records;
        this.total = response.data.total;
        this.loading = false;
        let zrCount = 0;

        if(createFlag){
          this.list.map(item =>{
            if(item.code==="ZRWINFO"){
              zrCount = zrCount +1;
              this.zrid = item.id
            }
          })
          if(zrCount>0){
            this.showList = true;
            this.showCard = false;
            this.noZrAccount = false;
          }else{
            this.showList = false;
            this.showCard = true;
            this.noZrAccount = true;
          }
        }

      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        signature: null,
        code: null,
        status: 0,
        remark: null,
        apiKey: null,
        apiSecret: null,
        callbackUrl: null
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加短信渠道";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getChannel(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改短信渠道";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateChannel(this.form).then(response => {
              if(response.success){
                this.$message.success("修改成功");
                this.open = false;
                this.getList(true);
              }else {
                this.$message.error(response.message);
              }
            });
          } else {
            addChannel(this.form).then(response => {
              if(success){
                this.$message.success("新增成功");
                this.open = false;
                this.getList(true);
              }else {
                this.$message.error(response.message);
              }
            });
          }
        }
      });
    },
    /** 刪除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认刪除短信渠道为"' + ids + '"的数据项？').then(function() {
        return delChannel(ids);
      }).then(response => {
        if(response.success){
          this.getList();
          this.$message.success("刪除成功");
        }else {
          this.$message.error(response.message);
        }
      }).catch(() => {});
    },
    /** 導出按钮操作 */
    handleExport() {
      this.download('extra/channel/export', {
        ...this.queryParams
      }, `channel_${new Date().getTime()}.xlsx`)
    },
    onShowSizeChange (current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
  }
};
</script>
